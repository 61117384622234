import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { init } from "emailjs-com";
init("user_rvd2xXLkenPOY5eH7pbxf");

export default function ContactForm2() {
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [object, setObject] = useState("");
  const [message, setMessage] = useState("");
  const [output, setOutput] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");

  const maxLengthMsg = 1200;
  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const phoneFormat = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;

  const schema = yup.object().shape({
    lastName: yup.string().required().min(5),
    email: yup.string().required().min(5),
    phone: yup.number().min(5),
    subject: yup.string().required(),
    message: yup.string().required().min(5),
  });

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  function onSubmit(data, e) {
    emailjs
      .sendForm("gmail", "portfolio", e.target, "user_rvd2xXLkenPOY5eH7pbxf")
      .then(
        (result) => {
          reset(
            setLastName(""),
            setEmail(""),
            setPhone(""),
            setObject("", setMessage(""), setErrorSubmit(""))
          );
          setErrorSubmit(
            "Merci, votre message a été envoyé.",
            setTimeout(() => {
              setErrorSubmit("");
            }, 2000)
          );
        },
        (error) => {
          setErrorSubmit("Je suis désolée, votre message n'a pas été envoyé.");
        }
      );
  }

  const serviceLists = [
    {
      name: "Web Design",
      label: "webDesign",
    },
    {
      name: "UX/UI",
      label: "uxUi",
    },
    {
      name: "Motion Design",
      label: "motionDesign",
    },
    {
      name: "Illustration",
      label: "illustration",
    },
    {
      name: "Print : Affiche/Logotype",
      label: "printAfficheLogotype",
    },
  ];

  const ValideLastName = () => {
    return (
      <p
        style={{ marginTop: 15 }}
        className={lastName.length >= 5 ? "validate-message" : "error-message"}
      >
        {lastName.length >= 5
          ? "Nom et prénom valides."
          : "Veuillez entrer un Nom et Prénom valides."}
      </p>
    );
  };

  const ValideEmail = () => {
    return (
      <p
        style={{ marginTop: 15 }}
        className={
          !email.match(mailformat) ? "error-message" : "validate-message"
        }
      >
        {email.match(mailformat)
          ? "Email valide."
          : "Veuillez entrer un email valide."}
      </p>
    );
  };

  const MessageMaxLength = () => {
    return (
      <p
        style={{ marginTop: 15 }}
        className={
          message.length !== maxLengthMsg ? "validate-message" : "error-message"
        }
      >
        {message >= maxLengthMsg
          ? `Nombre de charactères maximum atteint.`
          : `Il vous reste ${maxLengthMsg - message.length} ${
              maxLengthMsg - message.length <= 1
                ? "charactère."
                : "charactères."
            }`}
      </p>
    );
  };

  const ValidePhone = () => {
    return (
      <p
        style={{ marginTop: 15 }}
        className={
          !phone.match(/^\d{10}$/g) ? "error-message" : "validate-message"
        }
      >
        {phone.match(/^\d{10}$/g)
          ? "Téléphone valide."
          : "Veuillez entrer un numéro de téléphone valide."}
      </p>
    );
  };

  const dataInput = {
    lastName: <ValideLastName />,
    email: <ValideEmail />,
    phone: <ValidePhone />,
    message: <MessageMaxLength />,
  };

  return (
    <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
      {lastName.length <= 5 ||
      email.length <= 5 ||
      object.length <= 5 ||
      message.length <= 5 ? (
        <span className="error-message">
          Veuillez remplir tous les champs avec un min de 5 charactères.
        </span>
      ) : (
        <span className="validate-message">Tous les champs sont remplis.</span>
      )}
      <input
        type="text"
        ref={register}
        name="lastName"
        value={lastName}
        placeholder="Nom et prénom obligatoire*"
        className="input-style"
        onChange={(e) => setLastName(e.target.value)}
      />
      {lastName.length === 0 ? "" : dataInput.lastName}
      <input
        type="email"
        ref={register}
        name="email"
        value={email}
        placeholder="E-mail obligatoire*"
        className="input-style"
        onChange={(e) => setEmail(e.target.value)}
      />
      {email.length === 0 ? "" : dataInput.email}

      <input
        type="tel"
        maxLength={10}
        ref={register}
        name="phone"
        value={phone}
        placeholder="Numéro de téléphone, non obligatoire"
        className="input-style"
        onChange={(e) => setPhone(e.target.value)}
      />
      {phone.length === 0 ? "" : dataInput.phone}

      <label htmlFor="services" className="services-name">
        Quels services vous intéressent ?
      </label>
      <div className="grid-services">
        {serviceLists.map((services, index) => {
          return (
            <div className="services-lists" key={index}>
              <input
                type="checkbox"
                ref={register}
                value={services.name}
                name="services"
              />
              <label htmlFor={services.label} className="label-services">
                {" "}
                {services.name}
              </label>
            </div>
          );
        })}
      </div>
      <input
        type="text"
        ref={register}
        maxLength={50}
        name="subject"
        value={object}
        placeholder="Objet obligatoire*"
        className="input-style"
        onChange={(e) => setObject(e.target.value)}
      />
      <textarea
        ref={register}
        maxLength={maxLengthMsg}
        name="message"
        value={message}
        id="textarea-contact"
        placeholder="Message obligatoire*"
        rows="10"
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>
      {message.length === 0 ? "" : dataInput.message}

      <button className="btn-contact">Envoyer</button>
      <p className="validate-message">{errorSubmit}</p>
    </form>
  );
}
