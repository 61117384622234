import ReactGA from "react-ga";
import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";

import "./App.scss";
import HomePage from "./Components/HomePage/Home";
import Nav from "./Components/Nav/Nav";
import About from "./Components/About/About";
import AboutPage from "./Components/AboutPage/AboutPage";
import IdentiteDeMarque from "./Components/Services/IdentiteDeMarque";
import WebDesign from "./Components/Services/WebDesign";
import Footer from "./Components/Footer/Footer";
import SupportCommunication from "./Components/Services/SupportCommunication";
import Illustrations from "./Components/Services/Illustrations";
import Creations from "./Components/Creations/Creations";
import ListsPages from "./Components/Creations/ListsPages";
import Contact from "./Components/Contact/Contact";
import PolitiquesConfidentialite from "./Components/Footer/PolitiquesConfidentialite";
import MentionsLegales from "./Components/Footer/MentionsLegales";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Helmet from "react-helmet";

const initGA = () => {
  ReactGA.initialize("G-BRR0BL6X1C"); // put your tracking id here
  ReactGA.pageview(window.location.pathname);
};

// const GApageView = (page) => {
//   ReactGA.pageview(page);
// }

// const GAmodalView = (modal) => {
//   ReactGA.modalview(modal);
// };
// GAmodalView("Request Demo");

// const GAevent = (categoryName, eventName) => {
//   ReactGA.event({
//       category: categoryName,  // Required
//       action: eventName,       // Required
//       label: 'labelName',
//       value: 10,
//       nonInteraction: false
//   });
// }

// const GAtiming = (categoryName, variableName, valueNum) => {
//   ReactGA.timing({
//       category: categoryName,
//       variable: variableName,
//       value: valueNum
//   });
// };

export default function App() {
  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    display: "block",
    transform: "translate(-50%,-50%)",
    width: 550,
    borderRadius: 5,
    maxWidth: "90%",
    padding: "20px",
    background: "#fff",
    boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.1)",
  };
  useEffect(() => {
    initGA();
  }, []);
  // useEffect(() => { GApageView("landing"); }, []);
  return (
    <Router>
      <div>
        <Helmet>
          <title>
            Portfolio de Emilie Pham Da - Graphiste Designer print/web à Paris
          </title>
          <meta
            name="description"
            content="Portfolio de Emilie Pham Da, graphiste designer print/web indépendante à Paris. Je conçois vos supports print et web pour sublimer votre communication et valoriser vos messages. ."
          />
          <meta name="robots" content="noodp" />
          <link rel="canonical" href="https://www.emiliephamda.com/"></link>
          <meta property="og:locale" content="fr_FR"></meta>
          <meta property="og:type" content="website"></meta>
          <meta
            property="og:site_name"
            content="Portfolio de Emilie Pham Da - Graphiste Designer print/web à Paris"
          ></meta>
          <meta
            property="og:title"
            content="Portfolio de Emilie Pham Da - Graphiste Designer print/web à Paris"
          ></meta>
          <meta
            name="og:description"
            content="Portfolio de Emilie Pham Da, graphiste designer print/web indépendante à Paris. Je conçois vos supports print et web pour sublimer votre communication et valoriser vos messages. ."
          />
          <meta
            property="og:url"
            content="https://www.emiliephamda.com/"
          ></meta>
          <meta
            property="og:site_name"
            content="Graphiste Designer print/web à Paris - Portfolio de Emilie Pham Da - "
          ></meta>
          <meta
            property="og:image"
            content="http://emiliephamda.com/img/portfolio/carte-de-visite-1-emilie-pham-da.jpg"
          ></meta>
        </Helmet>
        <CookieConsent
          enableDeclineButton
          flipButtons
          overlay={true}
          overlayClasses="overlay"
          location="none"
          buttonText="Accepter"
          declineButtonText="Refuser"
          cookieName="epdckie"
          style={styles}
          buttonStyle={{
            cursor: "pointer",
            padding: "10px 20px",
            backgroundColor: "#e61b76",
            marginTop: 6,
            color: "#fff",
            fontSize: "13px",
          }}
          declineButtonStyle={{
            cursor: "pointer",
            padding: "10px 20px",
            backgroundColor: "#4f0fd5",
            color: "#fff",
            marginLeft: -10,
            marginTop: 6,
            fontSize: "13px",
          }}
          expires={365}
        >
          <h3 style={{ color: "#4e0fd5" }}>
            Accepter les cookies de mon site sur ce navigateur ?
          </h3>
          <p style={{ marginTop: -10, color: "#784adb" }}>
            Ce site web utilise des cookies pour améliorer l'expérience des
            utilisateurs et pour personnaliser le contenu.
          </p>
        </CookieConsent>

        <Nav />
        <ScrollToTop initialHeight={200} />
        <Route>
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/identite-de-marque" component={IdentiteDeMarque} />
            <Route path="/webdesign" component={WebDesign} />
            <Route
              path="/support-communication"
              component={SupportCommunication}
            />
            <Route path="/illustrations" component={Illustrations} />
            <Route path="/portfolio" exact component={Creations} />
            <Route path="/portfolio/:id" exact component={ListsPages} />
            <Route path="/qui-suis-je" component={AboutPage} />
            <Route path="/me-contacter" component={Contact} />
            <Route
              path="/politiques-confidentialites"
              component={PolitiquesConfidentialite}
            />
            <Route path="/mentions-legales" component={MentionsLegales} />
          </Switch>
        </Route>
        <Footer />
      </div>
    </Router>
  );
}
