import React, { Component } from "react";
import "./Webdesign.scss";
import BlocCreations from "../BlocCreations/BlocCreations";
import BlockCreaInfosText from "../BlockCreaInfosText/BlockCreaInfosText";

export default class Webdesign extends Component {
  render() {
    const directory = process.env.PUBLIC_URL;
    return (
      <BlocCreations id="webdesign" flexDirection="row-reverse">
        <div className="blocCreaBlueSky">
          <div className="content-img">
            <img
              src={`${directory}/img/home/web-design/webdesign-sephora.png`}
              alt="Webdesign sephora"
              className="sephora-size auto absolute-creation"
            />
            <img
              src={`${directory}/img/home/web-design/webdesign-appli-mob-titi-burger.jpg`}
              alt="Webdesign application mobile titi burger"
              className="titi-burger-size auto marginTop"
            />
            <img
              src={`${directory}/img/home/web-design/webdesign-starbuck.png`}
              alt="Webdesign starbuck"
              className="starbuck-size auto"
            />
          </div>
        </div>
        <BlockCreaInfosText
          secondTitle="Webdesign : le design de votre site"
          bgIcon={`${directory}/img/home/web-design/grande-icone-webdesign.png`}
          icon={`${directory}/img/home/web-design/petite-icone-webdesign.svg`}
          alt="Icone violette webdesign"
          link="webdesign"
          txt="En m'inspirant de vos valeurs et de vos messages, je conçois l'identité visuelle de votre site internet ou de votre application mobile. Je conjugue mon talent artistique et mes compétences numériques pour vous aider à vous démarquer sur la toile."
          txtButton="Créer ou refondre l'identité graphique "
          btnWidth="500px"
        />
      </BlocCreations>
    );
  }
}
