import React, { Component } from "react";
import "./Illustrations.scss";
import BlocCreations from "../BlocCreations/BlocCreations";
import BlockCreaInfosText from "../BlockCreaInfosText/BlockCreaInfosText";

export default class Illustrations extends Component {
  render() {
    const directory = process.env.PUBLIC_URL;
    return (
      <BlocCreations id="print" flexDirection="row-reverse">
        <div className="blocCreaBlueSky">
          <div className="content-img">
            <img
              src={`${directory}/img/home/illustrations/teresa-claymore.png`}
              alt="Illustrations de jolie femme cheveux long de profil"
              className="my-bootik-size auto"
            />
            <img
              src={`${directory}/img/home/illustrations/mode.png`}
              alt="Illustrations de femme avec chapeau de face"
              className="retro-size auto"
            />
            <img
              src={`${directory}/img/home/illustrations/lightning.png`}
              alt="Illustrations de personnage féminin de Final Fantasy "
              className="affiche-cinema-size auto"
            />
          </div>
        </div>
        <BlockCreaInfosText
          secondTitle="Illustrations"
          bgIcon={`${directory}/img/home/illustrations/illustration-grande.png`}
          icon={`${directory}/img/home/illustrations/dessin-petit.svg`}
          alt="Icone violette illustrations"
          link="illustrations"
          txt="Quoi de mieux que l'illustration pour vous différencier ? Grâce à mes talents de dessinatrice, je créer pour vous un univers original autour de personnages, de bâtiments (architecture), de paysages et de natures mortes. J'interviens sur vos textes"
          txtButton="Adopter l'illustration comme outil"
          btnWidth="500px"
        />
      </BlocCreations>
    );
  }
}
