import React, { Component } from "react";
import About from "../About/About";
import Profil from "./Profil/Profil";
import imgEmilieAbout from "../../images/home/a-propos/emilie.png";
import Footer from "../Footer/Footer";

export default class AboutPage extends Component {
  render() {
    return (
      <div>
        <About
          sizeTitle="55px"
          idName="a-propos"
          sizeTxt="25px"
          className="padding-services"
          img={imgEmilieAbout}
          title="A propos"
          txt="Graphiste et illustratrice indépendante, je conçois vos supports print et web pour sublimer votre communication et valoriser vos messages. Ma passion pour des univers originaux tels que l’HeroicFantasy rendent mes créations uniques et originales."
        />
        <Profil />
      </div>
    );
  }
}
