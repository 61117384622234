import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import "./Nav.scss";
import logoCouleur from "../../images/home/logo-blanc.png";
import logoBlanc from "../../images/home/logo-blanc-2.png";

export default class Nav extends Component {
  state = {
    toogle: false,
    toogleCaret: false,
    scrolls: 0,
  };

  showMenu = () => {
    this.setState({
      toogle: !this.state.toogle,
    });
  };

  showCaret = () => {
    this.setState({
      toogleCaret: !this.state.toogleCaret,
    });
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      this.setState({
        scrolls: window.scrollY,
      });
    });
  }

  render() {
    const { toogle, toogleCaret, scrolls } = this.state;
    const w = window.location.pathname;

    function bg() {
      const scrVals = scrolls >= 5;
      if (w.includes("newsletter")) {
        return scrVals ? "#FFE465" : "#FE3837";
      } else if (w.includes("carte-de-visite-mallyance")) {
        return scrVals ? "#FFE465" : "#5B5B5B";
      } else if (w.includes("affiches-cinema")) {
        return scrVals ? "#FFE465" : "#D83D04";
      } else if (w.includes("flyers-my-bootik")) {
        return scrVals ? "#FFE465" : "#06121E";
      } else if (w.includes("logotype")) {
        return scrVals ? "#FFE465" : "#4AD4F1";
      } else if (w.includes("carte-de-visite-graphiste")) {
        return scrVals ? "#FFE465" : "#28272C";
      } else if (w.includes("carte-de-visite-freelance")) {
        return scrVals ? "#FFE465" : "#3FDDF6";
      } else if (w.includes("magazine-retro-game")) {
        return scrVals ? "#FFE465" : "#4AD4F1";
      } else if (w.includes("titi-burger")) {
        return scrVals ? "#FFE465" : "#0E2840";
      } else if (w.includes("application-mobile")) {
        return scrVals ? "#FFE465" : "#4BD4F4";
      } else if (w.includes("paper-toy")) {
        return scrVals ? "#FFE465" : "#FE3837";
      } else if (w.includes("coca-cola-packaging")) {
        return scrVals ? "#FFE465" : "#862512";
      } else if (w.includes("mockup-shoes")) {
        return scrVals ? "#FFE465" : "#FFEA59";
      } else if (w.includes("carnet-illustration")) {
        return scrVals ? "#FFE465" : "#D7E171";
      } else {
        return "#FFE465";
      }
    }

    function changeImg() {
      if (scrolls >= 5) {
        return logoCouleur;
      } else if (w.includes("/portfolio/")) {
        return logoBlanc;
      } else {
        return logoCouleur;
      }
    }

    function changeItems() {
      if (w.includes("/") && !w.includes("/portfolio/")) {
        return "anchors-items noto-regular";
      } else if (w.includes("/portfolio/") && scrolls === 0) {
        return "anchors-items portfolio-link noto-regular";
      } else {
        return "anchors-items noto-regular";
      }
    }
    const anchorTime = w.includes("/portfolio/") ? "#fff" : "#4c0bd9";
    return (
      <nav
        class={`${scrolls >= 5 ? "nav nav-affix" : "nav"}`}
        style={{ backgroundColor: `${bg()}`, color: "#fff !important" }}
      >
        <div className="flex">
          <div className="logo">
            <a href="/">
              <img
                src={`${changeImg()}`}
                alt={`${
                  w.includes("/portfolio/") ? "logo blanc" : "logo couleur"
                }`}
                className="logo-size"
              />
            </a>
          </div>
          <ul
            className={
              toogle ? "flex-list list-menu show" : `flex-list list-menu`
            }
          >
            <li className="list-items">
              <a className={`${changeItems()}`} href="/portfolio">
                Portfolio
              </a>
            </li>
            <li className="list-items" id="services-items">
              <a
                className={`${changeItems()}`}
                onClick={() => this.showCaret()}
              >
                Compétences <i class="fas fa-caret-down"></i>
              </a>
              <ul
                className={`${toogleCaret ? "sub-menu showCaret" : "sub-menu"}`}
              >
                <li className="list-items">
                  <a
                    className="anchors-items noto-regular"
                    href="/identite-de-marque"
                  >
                    Identité de marque
                  </a>
                </li>
                <li className="list-items">
                  <a className="anchors-items noto-regular" href="/webdesign">
                    Webdesign
                  </a>
                </li>
                <li className="list-items">
                  <a
                    className="anchors-items noto-regular"
                    href="/support-communication"
                  >
                    Support communication
                  </a>
                </li>
                <li className="list-items">
                  <a
                    className="anchors-items noto-regular"
                    href="/illustrations"
                  >
                    Illustrations
                  </a>
                </li>
              </ul>
            </li>
            <li className="list-items">
              <a className={`${changeItems()}`} href="/qui-suis-je">
                Qui suis-je ?
              </a>
            </li>
            <li className="list-items">
              <a className={`${changeItems()}`} href="/me-contacter">
                Me Contacter
              </a>
            </li>
          </ul>

          <div className="menu-icon" onClick={this.showMenu}>
            <div className="icon"></div>
            <div className="icon"></div>
            <div className="icon"></div>
          </div>
        </div>
      </nav>
    );
  }
}
