import React, { useState } from "react";
import "./ListsPages.scss";
import print from "../../Object/print.json";
import web from "../../Object/web.json";
import packaging from "../../Object/packaging.json";
import illustration from "../../Object/illustration.json";
import videos from "../../Object/videos.json";
import gif from "../../Object/gif.json";

import affiches from "../../Object/affichesCinema.json";
import flyers from "../../Object/flyersMyBootik.json";
import coca from "../../Object/cocaCola.json";
import magazine from "../../Object/magazine.json";
import carteVFreelance from "../../Object/carteVisiteFreelance.json";
import carteDeVisite from "../../Object/carteVisite.json";
import ttBurger from "../../Object/titiBurger.json";
import lgotype from "../../Object/logotype.json";
import ppToy from "../../Object/paperToy.json";
import sephoraNewsletter from "../../Object/sephora.json";
import mallyanceCrea from "../../Object/mallyance.json";
import illustrationDigital from "../../Object/illustrationDigital.json";
import mockupShoes from "../../Object/mockup.json";
import ttBurgerMobile from "../../Object/titiBurgerMobile.json";
import showrealMotionCv from "../../Object/showrealMotionCv.json";
import storyboardRpg from "../../Object/storyboardRpg.json";
import teaserSrg from "../../Object/teaserSrg.json";
import spaceDream from "../../Object/spaceDream.json";
import artsupParis from "../../Object/testProjetEArtsupParis.json";
import fireSquid from "../../Object/fireSquid.json";
import reelsInstagram from "../../Object/reelsInstagram.json";
import gamingChaineYoutube from "../../Object/gamingChaineYoutube.json";
import trissColors from "../../Object/triss-colors.json";
import instagramFanart from "../../Object/instagram-fanart.json";

import HorizontalSlide from "./HorizontalSlide";

import Helmet from "react-helmet";

import instagramIcon from "../../images/home/footer/instagram-icon.svg";
import { Link } from "react-router-dom";

const pub = process.env.PUBLIC_URL;

const dataList = print.concat(web, packaging, illustration, videos, gif);

const affichesCinema = affiches;
const flyersMyBootik = flyers;
const cocaCola = coca;
const magazineRetroGame = magazine;
const carteVisiteFreelance = carteVFreelance;
const titiBurger = ttBurger;
const logotype = lgotype;
const carteVisiteGraphiste = carteDeVisite;
const paperToy = ppToy;
const sephora = sephoraNewsletter;
const mallyance = mallyanceCrea;
const illustrationDigit = illustrationDigital;
const mockup = mockupShoes;
const titiBurgerMobile = ttBurgerMobile;
const showRealMotion = showrealMotionCv;
const storyBoard = storyboardRpg;
const teaser = teaserSrg;
const spaceDreams = spaceDream;
const artsupsParis = artsupParis;
const fireSquids = fireSquid;
const reelsInsta = reelsInstagram;
const gamingChaineYtb = gamingChaineYoutube;
const trissColor = trissColors;
const instaFanart = instagramFanart;

let dataVals = [];

const linkName = [
	{
		url: "/portfolio/carte-de-visite-graphiste",
		name: "carte-de-visite-graphiste"
	},
	{
		url: "/portfolio/carte-de-visite-mallyance",
		name: "carte-de-visite-mallyance"
	},
	{
		url: "/portfolio/affiches-cinema",
		name: "affiches-cinema"
	},
	{
		url: "/portfolio/flyers-my-bootik",
		name: "flyers-my-bootik"
	},
	{
		url: "/portfolio/carte-de-visite-freelance",
		name: "carte-de-visite-freelance"
	},
	{
		url: "/portfolio/magazine-retro-game",
		name: "magazine-retro-game"
	},
	{
		url: "/portfolio/logotype",
		name: "logotype"
	},
	{
		url: "/portfolio/titi-burger",
		name: "titi-burger"
	},
	{
		url: "/portfolio/newsletter",
		name: "newsletter"
	},
	{
		url: "/portfolio/application-mobile",
		name: "application-mobile"
	},
	{
		url: "/portfolio/paper-toy",
		name: "paper-toy"
	},
	{
		url: "/portfolio/coca-cola-packaging",
		name: "coca-cola-packaging"
	},
	{
		url: "/portfolio/mockup-shoes",
		name: "mockup-shoes"
	},
	{
		url: "/portfolio/carnet-illustration",
		name: "carnet-illustration"
	},
	{
		url: "/portfolio/showreal-motion-cv",
		name: "showreal-motion-cv"
	},
	{
		url: "/portfolio/storyboard-rpg",
		name: "storyboard-rpg"
	},
	{
		url: "/portfolio/teaser-srg",
		name: "teaser-srg"
	},
	{
		url: "/portfolio/space-dream",
		name: "space-dream"
	},
	{
		url: "/portfolio/test-projet-e-artsup-paris",
		name: "test-projet-e-artsup-paris"
	},
	{
		url: "/portfolio/firesquid",
		name: "firesquid"
	},
	{
		url: "/portfolio/reels-instagram",
		name: "reels-instagram"
	},
	{
		url: "/portfolio/gaming-chaine-youtube",
		name: "gaming-chaine-youtube"
	},
	{
		url: "/portfolio/triss-colors",
		name: "triss-colors"
	},
	{
		url: "/portfolio/instagram-fanart",
		name: "instagram-fanart"
	}
];

function Arrow() {
	const windowUrl = window.location.pathname;
	var number = 0;
	linkName.map((urls, index) => {
		if (windowUrl.includes(urls.url)) {
			number = index;
			return index;
		}
	});

	const [numberIndex, setNumberIndex] = useState(number);

	function prev() {
		if (numberIndex == 0) {
			var number = linkName.length - 1;
			setNumberIndex(number);
		} else {
			var number = numberIndex - 1;
			setNumberIndex(number);
		}
	}

	function next() {
		if (numberIndex == linkName.length - 1) {
			var number = 0;
			setNumberIndex(number);
		} else {
			var number = numberIndex + 1;
			setNumberIndex(number);
		}
	}

	return (
		<div className="controls">
			<div className="space-around">
				<a
					title={`${
						numberIndex === 0
							? linkName[linkName.length - 1].name
							: linkName[numberIndex - 1].name
					}`}
					href={`${linkName[numberIndex].url}`}
					onClick={prev}
					rel="prev"
					className="controls-name prev"
				>
					<i class="fas fa-chevron-left icon-controls icon-prev"></i>
					<span>Projet précédent</span>
				</a>

				<a href="/portfolio">
					<i class="fas fa-th icon-controls icon-back"></i>
				</a>
				<a
					title={`${
						numberIndex === linkName.length - 1
							? linkName[0].name
							: linkName[numberIndex + 1].name
					}`}
					href={`${linkName[numberIndex].url}`}
					onClick={next}
					rel="next"
					className="controls-name next"
				>
					<span>Projet suivant</span>
					<i class="fas fa-chevron-right icon-controls icon-next"></i>
				</a>
			</div>
		</div>
	);
}

function BlockInfos({ title, txt, bg, img }) {
	const backgroundBox = {
		background: `${bg}`,
		padding: "40px 20px",
		color: "#fff"
	};

	return (
		<React.Fragment>
			<div className="content center" style={backgroundBox}>
				<a
					href="/portfolio"
					className="arrow-left"
					style={{
						fontSize: 50,
						color: "#fff",
						textDecoration: "none",
						position: "absolute",
						left: 30
					}}
				>
					{/* <i class="fal fa-long-arrow-left"></i> */}
				</a>
				<div className="content-txt">
					<h2 className="f-size-45px" style={{ marginTop: 5, fontSize: 45 }}>
						{title}
					</h2>
					<p
						dangerouslySetInnerHTML={{ __html: txt }}
						className="size-txt"
						style={{ fontSize: 25, marginTop: 15 }}
					/>
				</div>
			</div>
			<div className="block-img-portfolio">
				<img
					src={
						window.location.pathname.includes("affiches-cinema")
							? `${pub}/img/portfolio/print/ordi/affiche-present.jpg`
							: img &&
							  window.location.pathname.includes("gaming-chaine-youtube")
							? `${pub}/img/portfolio/videos/lightning-gaming.jpg`
							: img
					}
					alt="Image portfolio"
					className="img-portfolio"
				/>
			</div>
		</React.Fragment>
	);
}

function ListsPages() {
	const locationName = window.location.pathname;
	const prints = dataList.map((el) => {
		const name = el.DefaultsCreations.map((names) => {
			return names.name;
		});
		const v = el.DefaultsCreations.map((els) => {
			const pub = "../";
			if (locationName.includes(name)) {
				return (
					<div>
						<BlockInfos
							title={els.mainTitle}
							txt={els.txtInformations}
							bg={els.bgColor}
							img={pub + els.img}
						/>
					</div>
				);
			}
		});

		return [v];
	});

	// Component horizontal images

	// Print creations

	// Affiche cinéma
	const afficheCinemaPrint = affichesCinema.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("affiches-cinema")) {
					return (
						<div id="portfolio-affiches-cinema" className="content-portfolio">
							<Helmet>
								<title>
									Affiches cinéma - Graphiste Designer print/web à Paris -
									Portfolio de Emilie Pham Da
								</title>
								<meta
									name="description"
									content="Graphisme, montage photo et impression, affiche d'événement pour l’Extra life café l'an 2015, affiche du film « 30 ans sinon rien"
								/>
							</Helmet>
							<div className="content-txt-portfolio">
								<b className="txt-portfolio">
									Client : Extra life café (Paris)
								</b>
								<h2 className="title-portfolio dark-blue-color">
									{datas.title}
								</h2>
								<p className="txt-portfolio">
									Extra life café est un lieu de rencontre convivial pour les
									passionnés de jeux vidéo et de culture japonaise. Les clients
									peuvent profiter d’un espace restauration, d’un coin calme
									avec des mangas et des jeux de société, ainsi que d’une salle
									de jeux d’arcade avec des consoles rétro-gaming.
								</p>
								<p className="txt-portfolio">
									<b>Objectif : </b>
									{datas.informations}
								</p>
								<p className="txt-portfolio">
									<b>Partis-pris créatif : </b>
									{datas.informationsSecond}
								</p>
							</div>
							<HorizontalSlide vals={datas.imgSlide} />
							{list.PartTwo.map((datas, index) => {
								return (
									<div>
										<div className="content-txt-portfolio">
											<b className="txt-portfolio">
												Projet fictif : Film « 30 ans sinon rien »
											</b>
											<h2 className="title-portfolio dark-blue-color">
												{datas.title}
											</h2>
											<p className="txt-portfolio">
												<b>Synopsis :</b> Jenna, une adolescente de treize ans
												qui rêve de devenir célèbre, se retrouve projeter dans
												le temps de manière surnaturelle et devient une jeune
												femme sur le point de célébrer son trentième
												anniversaire. Devenue belle et populaire, elle profite
												de sa nouvelle vie mais découvre rapidement que l’homme
												qu’elle aime s'apprête à épouser une autre femme.
											</p>
											<p className="txt-portfolio">
												<b>Objectif : </b>
												{datas.informations}
											</p>
											<p className="txt-portfolio">
												<b>Partis-pris créatif : </b>
												{datas.informationsSecond}
											</p>
										</div>
										<HorizontalSlide vals={datas.imgSlide} />
									</div>
								);
							})}
						</div>
					);
				}
			});
		});
	});

	// Flyers my bootik
	const flyerMyBootikPrint = flyersMyBootik.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("flyers-my-bootik")) {
					return (
						<div id="portfolio-flyers-my-bootik" className="content-portfolio">
							<div className="content-txt-portfolio">
								<b className="txt-portfolio">Client : My Bootik</b>
								<h2 className="title-portfolio dark-blue-color">
									{datas.title}
								</h2>
								<p className="txt-portfolio">
									Ce magasin situé dans Paris est spécialisé dans le cosplay
									(déguisement pour jeux de rôle), la vente de lentilles, de
									maquillage, de colorations pour cheveux, de costumes et
									déguisements et d’accessoires divers.
								</p>
								<p className="txt-portfolio">
									<b>Objectif : </b>
									{datas.informations}
								</p>
								<p className="txt-portfolio">
									<b>Partis-pris créatifs :</b> {datas.informationsSecond}
								</p>
							</div>
							<HorizontalSlide vals={datas.imgSlide} />
						</div>
					);
				}
			});
		});
	});

	// Coca Cola
	const cocaColaPrint = cocaCola.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("coca-cola-packaging")) {
					return (
						<div id="portfolio-flyers-my-bootik" className="content-portfolio">
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									<b>Projet fictif : </b>Packaging canettes et sac Coca-Cola
								</p>
								<h2 className="txt-portfolio">{datas.title}</h2>
								<p className="txt-portfolio">
									<b>Objectif : </b>
									{datas.informations}
								</p>
								<p className="txt-portfolio">
									<b>Partis-pris créatifs : </b>
									<span
										dangerouslySetInnerHTML={{
											__html: datas.informationsSecond
										}}
									/>
								</p>

								<p className="txt-portfolio">
									<b>Deux versions : </b>Orange mélange tropical exotique et
									ainsi que le Coca Cola classique en rouge
								</p>
								<p className="txt-portfolio">
									J’ai réalisé un détourage du personnage Djiadane, héros de
									FINAL FANTASY 9, que j’ai placé au premier plan, en 3D, avec
									la capsule des Canettes de Coca-Cola. En arrière-plan, une
									illustration de fond permettant de se plonger dans l’univers
									et des décors du jeu.
								</p>
							</div>
							<HorizontalSlide vals={datas.imgSlide} />
							{list.PartTwo.map((datas, index) => {
								return (
									<div>
										<div className="content-txt-portfolio"></div>
										<HorizontalSlide vals={datas.imgSlide} />
									</div>
								);
							})}
							{list.PartThree.map((datas, index) => {
								return (
									<div>
										<HorizontalSlide vals={datas.imgSlide} />
									</div>
								);
							})}
						</div>
					);
				}
			});
		});
	});

	// Magazine retrogame
	const magazineRetroGamePrint = magazineRetroGame.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("magazine-retro-game")) {
					return (
						<div
							id="portfolio-magazine-retrogame"
							className="content-portfolio"
						>
							<div className="content-txt-portfolio">
								<h3 class="txt-portfolio">
									Client : Agence de communication Greatwood pour le Magazine
									Rétro Game Réalisation de supports de communication print
								</h3>
								{/* <h2 className="title-portfolio dark-blue-color">{datas.title}</h2> */}
								<div className="txt-portfolio">
									Greatwood est une agence de communication qui propose des
									services de communication globale, print, PLV, web et
									branding. Le Magazine Rétro Game lui a confié la refonte de
									son catalogue de jeux vidéo.
								</div>
								<p className="txt-portfolio">
									<b>Objectif : </b>
									{datas.informations}
								</p>
								<p className="txt-portfolio">
									<b>Partis-pris créatif : </b>
									{datas.informationsSecond}
								</p>
							</div>
							<HorizontalSlide vals={datas.imgSlide} />
							{list.PartTwo.map((datas, index) => {
								return (
									<div>
										<HorizontalSlide vals={datas.imgSlide} />
									</div>
								);
							})}
						</div>
					);
				}
			});
		});
	});

	// Carte de visite freelance
	const carteDeVisiteFreelancePrint = carteVisiteFreelance.map(
		(data, index) => {
			return data.ListSlide.map((list, index) => {
				return list.PartOne.map((datas, index) => {
					if (locationName.includes("carte-de-visite-freelance")) {
						return (
							<div
								id="portfolio-carte-de-visite-freelance"
								className="content-portfolio"
							>
								<div className="content-txt-portfolio">
									<b className="txt-portfolio">Client : Emilie Pham Da</b>
									<h2 className="title-portfolio dark-blue-color">
										{datas.title}
									</h2>

									<p className="txt-portfolio">
										Emilie Pham Da se lance sa propre activité de graphiste
										print et web, et d’illustratrice
									</p>
									<p className="txt-portfolio">
										<b>Objectif : </b>
										{datas.informations}
									</p>
									<p className="txt-portfolio">
										<b>Partis-pris créatifs : </b>
										{datas.informationsSecond}{" "}
									</p>
								</div>
								<HorizontalSlide vals={datas.imgSlide} />
							</div>
						);
					}
				});
			});
		}
	);

	// Titi burger
	const titiBurgerPrint = titiBurger.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("titi-burger")) {
					return (
						<div id="portfolio-titi-burger" className="content-portfolio">
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									<b>Projet fictif : </b>Titi Burger
								</p>

								<h2 className="title-portfolio dark-blue-color">
									{datas.title}
								</h2>
								<p className="txt-portfolio">
									Titi burger est une chaîne de restauration de burgers végétaux
									« faits maison »
								</p>
								<p className="txt-portfolio">
									<b>Objectif : </b>
									{datas.informations}
								</p>
								<br />
								<span className="txt-portfolio">
									<b>Partis-pris créatifs : </b>
									{datas.informationsSecond}
								</span>
							</div>
							<HorizontalSlide vals={datas.imgSlide} />
							<a
								target="_blank"
								href="https://xd.adobe.com/view/d495829c-cf13-40e3-acdb-5328457fe7a7-81c6/?fullscreen&hints=off"
								style={{
									width: "70%",
									margin: "auto",
									textAlign: "center",
									padding: "15px",
									background: "#ffd600",
									marginTop: 45,
									display: "block",
									textDecoration: "none",
									color: "#fff",
									fontSize: 25
								}}
							>
								Voir le prototype
							</a>
						</div>
					);
				}
			});
		});
	});

	// Logotype
	const logotypePrint = logotype.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("logotype")) {
					return (
						<div id="portfolio-logotype" className="content-portfolio">
							<div className="content-txt-portfolio">
								<h2 className="title-portfolio dark-blue-color">
									{datas.title}
								</h2>
								<b className="txt-portfolio">
									Sujet développé la marque de chaussure Chabadou{" "}
								</b>
								<b className="txt-portfolio">
									Réalisation de logotypes pour une marque de chaussures
								</b>
								<p className="txt-portfolio">
									<b>Objectif : </b>
									{datas.informations}
								</p>
							</div>
							<HorizontalSlide vals={datas.imgSlide} />

							{list.PartTwo.map((datas, index) => {
								return (
									<div className="content-txt-portfolio">
										<p className="txt-portfolio">
											<b>Client : </b>
											Greatwood communication
										</p>
										<b className="txt-portfolio">{datas.title}</b>
										<p className="txt-portfolio">
											Pour la marque de l’activité Magazine Rétro game
										</p>
										<p className="txt-portfolio">
											<b>Objectif : </b>
											{datas.informations}
										</p>
										<p className="txt-portfolio">
											<b>Partis-pris créatif : </b>
											{datas.informationsSecond}
										</p>
										<HorizontalSlide vals={datas.imgSlide} />
									</div>
								);
							})}
						</div>
					);
				}
			});
		});
	});

	// Carte de visite graphiste
	const carteDeVisiteGraphistePrint = carteVisiteGraphiste.map(
		(data, index) => {
			return data.ListSlide.map((list, index) => {
				return list.PartOne.map((datas, index) => {
					if (locationName.includes("carte-de-visite-graphiste")) {
						return (
							<div
								id="portfolio-carte-de-visite-graphiste"
								className="content-portfolio"
							>
								<div className="content-txt-portfolio">
									<b class="txt-portfolio">Clients : ffviman.fr</b>
									<h2 className="title-portfolio dark-blue-color">
										{datas.title}
									</h2>

									<p
										dangerouslySetInnerHTML={{ __html: datas.informations }}
										className="txt-portfolio"
									/>
									<p className="txt-portfolio">
										<b>Partis-pris créatifs : </b>
										{datas.informationsSecond}
									</p>
								</div>
								<HorizontalSlide vals={datas.imgSlide} />
							</div>
						);
					}
				});
			});
		}
	);

	// Paper toy
	const paperToyPrint = paperToy.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("paper-toy")) {
					return (
						<div id="portfolio-paper-toy" className="content-portfolio">
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									<b>Projet fictif : </b>Conception d’un patron en papier dans
									le cadre de la formation Infographiste Metteur en page
								</p>
								<p className="txt-portfolio">
									<b>Réalisation : </b>Patron composée sur Illustrator et
									impression papier plus montage à la main du bonhomme papier.
								</p>
								<p className="txt-portfolio">
									<b>Objectif : </b>
									{datas.informations}
								</p>{" "}
								<h2 className="title-portfolio dark-blue-color">
									{datas.title}
								</h2>{" "}
								<p className="txt-portfolio">
									<b>Partis-pris créatifs : </b>
									{datas.informationsSecond}
								</p>
							</div>
							<HorizontalSlide vals={datas.imgSlide} />
						</div>
					);
				}
			});
		});
	});

	// Sephora newsletter
	const sephoraWeb = sephora.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("newsletter")) {
					return (
						<div
							id="portfolio-sephora-newsletter"
							className="content-portfolio"
						>
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									<b>Projet fictif : </b>
									{datas.projetFictif}
								</p>
								<h2 className="title-portfolio dark-blue-color">
									{datas.title}
								</h2>
								<p className="txt-portfolio">
									<b>Objectif : </b>
									{datas.informations}
								</p>
								<p className="txt-portfolio">
									<b>Partis-pris créatifs : </b>
									{datas.informationsSecond}
								</p>
							</div>
							<HorizontalSlide vals={datas.imgSlide} />
							{list.PartTwo.map((datas, index) => {
								return (
									<div>
										<div className="content-txt-portfolio">
											<p className="txt-portfolio">
												<b>Projet fictif : </b>
												{datas.projetFictif}
											</p>
											<h2 className="title-portfolio dark-blue-color">
												{datas.title}
											</h2>
											<p className="txt-portfolio">
												<b>Objectif : </b>
												{datas.informations}
											</p>
											<p className="txt-portfolio">
												<b>Partis-pris créatifs : </b>
												{datas.informationsSecond}
											</p>
										</div>
										<HorizontalSlide vals={datas.imgSlide} />
									</div>
								);
							})}
							{list.PartThree.map((datas, index) => {
								return (
									<div>
										<div className="content-txt-portfolio">
											<p className="txt-portfolio">
												<b>Projet fictif : </b>
												{datas.projetFictif}
											</p>
											<h2 className="title-portfolio dark-blue-color">
												{datas.title}
											</h2>
											<p className="txt-portfolio">
												<b>Objectif : </b>
												{datas.informations}
											</p>
											<p className="txt-portfolio">
												<b>Partis-pris créatifs : </b>
												{datas.informationsSecond}
											</p>
										</div>
										<HorizontalSlide vals={datas.imgSlide} />
									</div>
								);
							})}
						</div>
					);
				}
			});
		});
	});

	// Mallyance
	const mallyancePrint = mallyance.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("carte-de-visite-mallyance")) {
					return (
						<div id="portfolio-paper-toy" className="content-portfolio">
							<div className="content-txt-portfolio">
								<b class="txt-portfolio">Client : Mallyance</b>
								<h2 className="title-portfolio dark-blue-color">
									{datas.title}
								</h2>
								<p className="txt-portfolio">
									<b>Objectif : </b>
									{datas.informations}
								</p>
								<p className="txt-portfolio">
									<b>Choix créatif : </b>
									{datas.informationsSecond}
								</p>
							</div>
							<HorizontalSlide vals={datas.imgSlide} />
						</div>
					);
				}
			});
		});
	});

	// Web creations
	// Titi burger
	const titiBurgerMobileWeb = titiBurgerMobile.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("application-mobile")) {
					return (
						<div id="portfolio-titi-burger" className="content-portfolio">
							<HorizontalSlide vals={datas.imgSlide} />

							{list.PartTwo.map((datas, index) => {
								return (
									<div>
										<div className="content-txt-portfolio">
											<b className="txt-portfolio">
												Projet fictif : Titi Burger
											</b>
											<h2 className="title-portfolio dark-blue-color">
												{datas.title}
											</h2>

											<p className="txt-portfolio">
												<b>Objectif : </b>
												{datas.informations}
											</p>
											<p className="txt-portfolio">
												<b>Partis-pris créatifs : </b>
												{datas.informationsSecond}
											</p>
										</div>
										<a
											target="_blank"
											href="https://xd.adobe.com/view/091390d1-71d4-421e-6402-0a235e06ccfe-7f22/?fbclid=IwAR2569yazQqeeb33Ts9Dyj3_5eqOLKpq-wCHENFm2w_WkJxQQbzKXU0b10U"
											style={{
												width: "70%",
												margin: "auto",
												textAlign: "center",
												padding: "15px",
												background: "#ffd600",
												marginTop: 45,
												display: "block",
												textDecoration: "none",
												color: "#fff",
												fontSize: 25
											}}
										>
											Voir le prototype
										</a>
										<HorizontalSlide vals={datas.imgSlide} />
									</div>
								);
							})}
						</div>
					);
				}
			});
		});
	});

	// Packaging creations

	// Mockup
	const mockupPrint = mockup.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("mockup-shoes")) {
					return (
						<div id="portfolio-mockup-shoes" className="content-portfolio">
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									<b>Projet fictif : </b>Chabadou
								</p>
								<h2 className="title-portfolio dark-blue-color">
									{datas.title}
								</h2>
								<p className="txt-portfolio">
									<b>Objectif : </b>
									{datas.informations}
								</p>
							</div>
							<HorizontalSlide vals={datas.imgSlide} />
						</div>
					);
				}
			});
		});
	});

	// Illustrations
	const illustrationDigitIllustration = illustrationDigit.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("carnet-illustration")) {
					return (
						<div id="portfolio-illustration" className="content-portfolio">
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									<b>Projet fictif : </b> Fanart digital painting
								</p>
								<p
									dangerouslySetInnerHTML={{ __html: datas.informations }}
									className="txt-portfolio"
								/>
								<p className="txt-portfolio">
									<b>Inspiration : </b>Jeu vidéo Final Fantasy XIII
									<br />
									<b>Inspiration : </b>Mannequin Karina Lambard
									<br />
									<b>Inspiration : </b>Teresa Claymore <b>Manga d'origine</b>
								</p>
							</div>
							<HorizontalSlide vals={datas.imgSlide} />
							{list.PartTwo.map((datas, index) => {
								return (
									<div>
										<div className="content-txt-portfolio"></div>
										<HorizontalSlide vals={datas.imgSlide} />
									</div>
								);
							})}
							{list.PartThree.map((datas, index) => {
								return (
									<div>
										<div className="content-txt-portfolio"></div>
										<HorizontalSlide vals={datas.imgSlide} />
									</div>
								);
							})}
						</div>
					);
				}
			});
		});
	});

	// Videos

	const showRealMotionVideo = showRealMotion.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("showreal-motion-cv")) {
					return (
						<div id="portfolio-illustration" className="content-portfolio">
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									<b>Showreal motion design CV vidéo</b>
								</p>
								<p className="txt-portfolio">
									Présentation et animation parcours book design avec
									compétences techniques.
								</p>
							</div>
							<video width="100%" className="videos" controls>
								<source
									src={pub + "/videos/showreal-emilie.mp4"}
									type="video/mp4"
								/>
								Your browser does not support HTML video.
							</video>
						</div>
					);
				}
			});
		});
	});

	const storyBoardVideos = storyBoard.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("storyboard-rpg")) {
					return (
						<div id="portfolio-illustration" className="content-portfolio">
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									<b>Storyboard RPG court-métrage vidéo</b>
								</p>
								<p className="txt-portfolio">
									Composition des cases de 9 scènes en présentation et le résumé
									de l’histoire :
								</p>
							</div>
							<video width="100%" className="videos" controls>
								<source src={pub + "/videos/story-rgp.mp4"} type="video/mp4" />
								Your browser does not support HTML video.
							</video>
						</div>
					);
				}
			});
		});
	});

	const teaserVideos = teaser.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("teaser-srg")) {
					return (
						<div id="portfolio-illustration" className="content-portfolio">
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									<b>Client : Agence de communication Greatwood</b>
								</p>
								<p className="txt-portfolio">
									<b>
										Projet Animation Vidéo Teaser Super Rétro Game pour la
										sortie du site{" "}
										<a href="https://www.super-retrogame.fr/" target="_blank">
											www.super-retrogame.fr
										</a>
									</b>
								</p>
								<div className="txt-portfolio">
									Greatwood est une agence de communication qui propose des
									services de communication globale, print, PLV, web et
									branding. La boutique en ligne Super Rétro Game m’a été
									confiée à la refonte de son site web afin d’y vendre des
									goodies, des jeux vidéo rétro, des jeux vidéo arcade. Chargé
									de faire la promo du site web, un Teaser a été prévu pour la
									sortie officielle.
								</div>
								<p className="txt-portfolio">
									<b>Objectif : </b>Diffuser l’image de la marque en promotion
									vidéo
								</p>
								<p className="txt-portfolio">
									<b>Partis-pris créatif :</b> Montage et animation typographie
									grand titre, des couleurs froides qui rendent la vidéo plus
									attractive.
								</p>
							</div>
							<video width="100%" className="videos" controls>
								<source src={pub + "/videos/teaser-srg.mp4"} type="video/mp4" />
								Your browser does not support HTML video.
							</video>
						</div>
					);
				}
			});
		});
	});

	// GIFS

	// Space Dream
	const spaceDreamGif = fireSquids.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("space-dream")) {
					return (
						<div id="portfolio-flyers-my-bootik" className="content-portfolio">
							<div className="content-txt-portfolio">
								<b className="txt-portfolio">Projet fictif : Space Dream</b>
								<h2 className="title-portfolio dark-blue-color">
									Animation Caméra directionnelle
								</h2>
								<p className="txt-portfolio">
									Une animation avec des icônes et titres complémentaires
									suivant les planètes en vue directionnelle d’une caméra 3D
									dans After effects ainsi que le bouton “Réserver” pour la
									commande de votre voyage spatial.
								</p>

								<p className="txt-portfolio">
									VIDEO ANIMATION GIF intro-espace
								</p>
							</div>
							<video width="100%" className="videos" controls>
								<source src={pub + "/gif/Intro-espace.mp4"} type="video/mp4" />
								Your browser does not support HTML video.
							</video>
							<div className="content-txt-portfolio" style={{ marginTop: 90 }}>
								<h2 className="title-portfolio dark-blue-color">
									Animation Vaisseau spatial
								</h2>
								<p className="txt-portfolio">
									Une animation avec une icône volante et une animation de Titre
								</p>

								<b className="txt-portfolio">Objectif :</b>

								<p className="txt-portfolio">
									Exercice technique en animation, apprendre les bases de
									l’animation et des outils After Effects.
								</p>

								<p className="txt-portfolio">
									VIDEO ANIMATION GIF vaisseau-flamme
								</p>
							</div>
							<video width="100%" className="videos" controls>
								<source
									src={pub + "/gif/vaiseau-flamme.mp4"}
									type="video/mp4"
								/>
								Your browser does not support HTML video.
							</video>
						</div>
					);
				}
			});
		});
	});

	// Artsup Paris
	const artsupParisGif = artsupsParis.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("test-projet-e-artsup-paris")) {
					return (
						<div id="portfolio-flyers-my-bootik" className="content-portfolio">
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									E artsup test d’entrée formation motion designer master 1
									alternance 2021
								</p>
								<h2 className="title-portfolio dark-blue-color">
									{datas.title}
								</h2>
								<p className="txt-portfolio">{datas.informations}</p>
								<b className="txt-portfolio">Objectif : </b>
								<p className="txt-portfolio">{datas.informationsSecond}</p>
								<p className="txt-portfolio">{datas.informationsThird}</p>
								<p className="txt-portfolio">{datas.informationsFour}</p>
							</div>
							<video width="100%" className="videos" controls>
								<source
									src={pub + "/gif/teste-e-art-sup.mp4"}
									type="video/mp4"
								/>
								Your browser does not support HTML video.
							</video>
						</div>
					);
				}
			});
		});
	});

	// Artsup Paris
	const fireSquidGif = fireSquids.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("firesquid")) {
					return (
						<div id="portfolio-flyers-my-bootik" className="content-portfolio">
							<div className="content-txt-portfolio">
								<h2 className="title-portfolio dark-blue-color">
									{datas.title}
								</h2>
								<p className="txt-portfolio">{datas.informations}</p>
								<b className="txt-portfolio">Objectif : </b>
								<p className="txt-portfolio">{datas.informationsSecond}</p>
								<p className="txt-portfolio">{datas.informationThird}</p>

								<div className="txt-portfolio">
									<h3 style={{ textAlign: "center", marginTop: 60 }}>
										veuillez trouver l'animation en réel sur Instagram:
									</h3>
									<div className="content-icon-big icon-instagram-big">
										<a
											href="https://www.instagram.com/lightningcreative/"
											target="_blank"
										>
											<img
												src={instagramIcon}
												alt="instragam icon "
												className="icon-footer"
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					);
				}
			});
		});
	});

	// Reels instagram
	const reelsInstaVideos = reelsInsta.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("reels-instagram")) {
					return (
						<div
							id="portfolio-magazine-retrogame"
							className="content-portfolio"
						>
							<div className="content-txt-portfolio">
								<h2 className="title-portfolio dark-blue-color">
									{datas.title}
								</h2>
								<p class="txt-portfolio">
									Life Is Strange est un jeu narratif incarnant un personnage
									principal et les personnages secondaires dans un scénario
									suivant une histoire triste comportant des choix différents et
									importants à faire dans cette aventure.
								</p>

								<p className="txt-portfolio">
									<b>Objectif : </b>
									de l’animation est de faire entendre parler du jeu ainsi que
									de montrer
									<b>
										{" "}
										une chaîne Youtube de joueur nommé “LIGHTNING_VERSUS
										Gameplay”
									</b>{" "}
									en train de streamer une vidéo de Gameplay (action du joueur
									dans une vidéo game)
								</p>
								<p className="txt-portfolio">
									<b>Partis-pris créatif : </b>
									{datas.informationsSecond}
								</p>
							</div>

							<video
								className="insta-videos"
								width="350"
								controls
								style={{ margin: "auto", marginTop: 60, display: "block" }}
							>
								<source
									src={pub + "/videos/promo-youtuber.mp4"}
									type="video/mp4"
								/>
								Your browser does not support HTML video.
							</video>

							<div className="content-txt-portfolio">
								<h2 className="title-portfolio dark-blue-color">
									Animation Titre et image : jeux vidéo Bishoujo Senshi Sailor
									Moon
								</h2>
								<p class="txt-portfolio">
									Bishoujo Senshi Sailor Moon est un jeu <b>RPG</b> au tour par
									tour adapté aux initiales de <b>Role Playing</b> Game qui
									désigne un type de jeu vidéo d'aventure. L'expression est
									adaptée du jeu de rôle sur table dont le <b>RPG</b> s'inspire
									du
									<b>
										J.D.R ( Le Jeu De Rôle ) qui se traduit par un jeu de
										société entre plusieurs joueurs racontant un scénario et une
										histoire afin de distribuer les rôles et classes de joueurs.
									</b>
								</p>
								<div className="txt-portfolio">
									<b>
										Exemple : Joueur 1 => Chevalier, Joueur 2 => Magicien etc...
									</b>
								</div>

								<p className="txt-portfolio">
									<b>Objectif : </b>
									L’intérêt de l’animation est de pouvoir partager la diffusion
									du jeu vidéo Bishoujo Senshi Sailor Moon qui a été traduit en
									version française par une communauté de fan francophone, car
									le jeu étant d’origine Japonais.
									<b>
										{" "}
										une chaîne Youtube de joueur nommé “LIGHTNING_VERSUS
										Gameplay”
									</b>{" "}
									en train de streamer une vidéo de Gameplay (action du joueur
									dans une vidéo game)
								</p>
								<p className="txt-portfolio">
									<b>Partis-pris créatif : </b>
									Une animation à titre unique pour la promotion du jeu Bishoujo
									Senshi Sailor Moon diffusé sur la chaîne Youtube
									LIGHTNING_VERSUS Gameplay.
								</p>
							</div>

							<video
								className="insta-videos"
								width="350"
								controls
								style={{ margin: "auto", marginTop: 60, display: "block" }}
							>
								<source
									src={pub + "/videos/bishoujo-senshi-sailor-moon.mp4"}
									type="video/mp4"
								/>
								Your browser does not support HTML video.
							</video>

							<div className="content-txt-portfolio">
								<h2 className="title-portfolio dark-blue-color">
									Animation publicitaire à l'enseigne food FIVE PIZZA ORIGINAL :
								</h2>
								<p className="txt-portfolio">
									<b>Partis-pris créatif : </b>
									Après un entretien bien sympathique un petit clin d’œil à
									cette entreprise FIVE PIZZA, j’ai eu l’idée de faire une
									animation pizza à prix de 5€ avec un effet de flou gaussien
									sur les formes : salade, tomate, pizza en vue de profondeur et
									d’effet parallaxe 3D.
								</p>
							</div>

							<video
								className="insta-videos"
								width="350"
								controls
								style={{ margin: "auto", marginTop: 60, display: "block" }}
							>
								<source
									src={pub + "/videos/five-pizza-original.mp4"}
									type="video/mp4"
								/>
								Your browser does not support HTML video.
							</video>
						</div>
					);
				}
			});
		});
	});

	// Gaming chaine youtube
	const gamingChaineYoutubeVideo = gamingChaineYtb.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("gaming-chaine-youtube")) {
					return (
						<div id="portfolio-flyers-my-bootik" className="content-portfolio">
							<div className="content-txt-portfolio">
								<h2 className="title-portfolio dark-blue-color">
									Animation Titre et image :
								</h2>
								<p className="txt-portfolio">
									Animation typographique et déclinaison du titre Chaine
									Streaming Pro Gaming au premier plan et en deuxième plan un
									avatar de personnage qui décrit la Chaîne Youtube Lightning
									Versus fun Gameplay.
								</p>

								<p className="txt-portfolio">
									<b>Objectif et partis-pris créatif : </b>
									L'intérêt de l’animation est de pouvoir marquer le début de la
									vidéo par une présentation typographique et de montrer le
									partage du streaming dans les domaines des loisirs et vidéo
									ludique des jeux vidéo.
								</p>

								<b className="txt-portfolio">
									Lien direct:
									<a
										className="ml-15"
										href="https://www.youtube.com/channel/UC6HhF--VLgc7kh0lqPFc6fg"
									>
										Voir la chaîne Youtube
									</a>
								</b>
							</div>

							<video
								className="videos"
								width="100%"
								controls
								style={{ margin: "auto", marginTop: 60, display: "block" }}
							>
								<source
									src={pub + "/videos/animation-chaine-youtube.mp4"}
									type="video/mp4"
								/>
								Your browser does not support HTML video.
							</video>
						</div>
					);
				}
			});
		});
	});

	// Triss Colors
	const illustrationTrissColors = trissColor.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("triss-colors")) {
					return (
						<div id="portfolio-flyers-my-bootik" className="content-portfolio">
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									<b>Le Concours Japan art Witcher 3 </b>
									organisé par CD Project page facebook et instagram
									<br />
									Récompense : Figurine et goodies
								</p>

								<p className="txt-portfolio">
									<b>Objectif : </b>
									Illustration faite à partir de concours qui encourage son
									niveau artistique en dessin numérique afin de pouvoir
									remporter une statue de figurine ou un goodies de witcher 3.
								</p>

								<p className="txt-portfolio">
									<b>Partis-pris créatif : </b>
									Un concours ouvert à tous débutant : Règlement dessiné un
									personnage de la série Witcher 3 en version tenue
									traditionnelle Japonais. Date de fin concours le 12/06/2022.
								</p>
							</div>

							<HorizontalSlide vals={datas.imgSlide} />

							<img
								className="width-60"
								src={pub + "/img/portfolio/illustration/paysage.jpg"}
								alt="Paysage"
							/>

							<img
								className="width-60"
								src={pub + "/img/portfolio/illustration/triss-colors.jpg"}
								alt="Triss Colors"
							/>
						</div>
					);
				}
			});
		});
	});

	//  Instragram Fanart
	const illustrationInstagramFanart = instaFanart.map((data, index) => {
		return data.ListSlide.map((list, index) => {
			return list.PartOne.map((datas, index) => {
				if (locationName.includes("instagram-fanart")) {
					return (
						<div id="portfolio-flyers-my-bootik" className="content-portfolio">
							<div className="content-txt-portfolio">
								<p className="txt-portfolio">
									<b>Objectif : </b>
									Illustration fanart pour le plaisir de dessiner sur Krita
									logiciel gratuit en open source.
								</p>

								<p className="txt-portfolio">
									<b>Partis-pris créatif : </b>
									Croquis du jeu King Quest VII de 1994 Studio d’animation
									Sierra effectué à la main sur du papier canson et utilisation
									de la tablette cintiq Wacom dans Krita afin d’y ajouter des
									couleurs et nuances variées.
								</p>
							</div>

							<HorizontalSlide vals={datas.imgSlide} />

							<img
								className="width-60"
								src={
									pub +
									"/img/portfolio/illustration/king-quest-doc--cadavre.jpg"
								}
								alt="King quest doc cadavre"
							/>

							<img
								className="width-60"
								src={
									pub +
									"/img/portfolio/illustration/king-quest-doc--cadavre01.jpg"
								}
								alt="TKing quest doc cadavre 1"
							/>

							<img
								className="width-60"
								src={
									pub +
									"/img/portfolio/illustration/king-quest-doc--cadavre-02.jpg"
								}
								alt="TKing quest doc cadavre 2"
							/>

							<div className="content-txt-portfolio" style={{ marginTop: 100 }}>
								<p className="txt-portfolio">
									Croquis du jeu Sam et Max The Road de1993 Studio d’animation
									Lucas effectué à la main sur du papier canson en noire et
									blanc.
								</p>
							</div>

							{list.PartTwo.map((datas, index) => {
								return <HorizontalSlide vals={datas.imgSlide} />;
							})}
						</div>
					);
				}
			});
		});
	});

	dataVals = [
		prints,
		afficheCinemaPrint,
		flyerMyBootikPrint,
		cocaColaPrint,
		magazineRetroGamePrint,
		carteDeVisiteFreelancePrint,
		titiBurgerPrint,
		logotypePrint,
		carteDeVisiteGraphistePrint,
		paperToyPrint,
		mallyancePrint,
		sephoraWeb,
		illustrationDigitIllustration,
		illustrationTrissColors,
		illustrationInstagramFanart,
		mockupPrint,
		titiBurgerMobileWeb,
		showRealMotionVideo,
		storyBoardVideos,
		gamingChaineYoutubeVideo,
		teaserVideos,
		reelsInstaVideos,
		spaceDreamGif,
		artsupParisGif,
		fireSquidGif
	];

	return (
		<div class="main-portfolio">
			{dataVals} <Arrow />
		</div>
	);
}

export default ListsPages;
