import React, { Component } from "react";
import "./Print.scss";
import BlocCreations from "../BlocCreations/BlocCreations";
import BlockCreaInfosText from "../BlockCreaInfosText/BlockCreaInfosText";

export default class Print extends Component {
  render() {
    const directory = process.env.PUBLIC_URL;
    return (
      <BlocCreations id="print">
        <div className="blocCreaBlueSky">
          <div className="content-img">
            <img
              src={`${directory}/img/home/print/my-bootik.png`}
              alt="Supports communication print mybootik"
              className="my-bootik-size auto"
            />
            <img
              src={`${directory}/img/home/print/retro.jpg`}
              alt="Supports communication print magazine retrogame"
              className="retro-size auto"
            />
            <img
              src={`${directory}/img/home/print/affiche-cinema.png`}
              alt="Supports communication print affiche cinéma 30 ans sinon rien"
              className="affiche-cinema-size auto"
            />
          </div>
        </div>
        <BlockCreaInfosText
          secondTitle="Vos supports de communication print"
          bgIcon={`${directory}/img/home/print/print-grand.png`}
          icon={`${directory}/img/home/print/print-petit.svg`}
          alt="Icone violette supports communication print"
          link="support-communication"
          txt="Outils de promotion intemporels et très efficaces, vos plaquettes, flyers, dépliants, brochures... méritent une attention particulière. Ils doivent refléter votre identité et véhiculer votre message. Je vous propose un graphisme original et en adéquation avec vos objectifs de communication."
          txtButton="Concevoir mes supports de communication"
          btnWidth="500px"
        />
      </BlocCreations>
    );
  }
}
