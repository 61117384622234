import React, { Component } from "react";
import "./IdentiteVisuelle.scss";
import BlocCreations from "../BlocCreations/BlocCreations";
import BlockCreaInfosText from "../BlockCreaInfosText/BlockCreaInfosText";

export default class LogoCreations extends Component {
  render() {
    const directory = process.env.PUBLIC_URL;

    return (
      <BlocCreations id="identite-visuelle">
        <div className="blocCreaBlueSky">
          <div className="content-img">
            <img
              src={`${directory}/img/home/identite-visuelle/shoes-produits.jpg`}
              alt="identité visuelle chabadoubox"
              className="chabadou-box-size"
            />
            <img
              src={`${directory}/img/home/identite-visuelle/oldeez.png`}
              alt="identité visuelle oldeez"
              className="oldeez-size"
            />
          </div>
        </div>
        <BlockCreaInfosText
          mainTitle="Comment je travaille sur vos projets ?"
          secondTitle="Votre identité visuelle"
          bgIcon={`${directory}/img/home/identite-visuelle/grande-identite-visuelle.png`}
          alt="Icone violette identité visuelle"
          icon={`${directory}/img/home/identite-visuelle/petite-identite-visuelle.svg`}
          link="identite-de-marque"
          txt="Je vous accompagne durant toute la création de votre identité visuelle. De la réflexion sur votre univers et vos messages, à la réalisation de votre logo, jusqu'au choix de vos couleurs, et de votre typographie. Je suis à vos côtés pour imaginer ensemble une identité de marque à décliner sur l'ensemble de vos supports de communication (réseaux sociaux, brochures, flyers, site web...)"
          txtButton="Créer mon identité visuelle"
          btnWidth="500px"
        />
      </BlocCreations>
    );
  }
}
