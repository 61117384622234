import React, { Component, useState, useEffect } from "react";
import Isotope from "isotope-layout";
import BtnCreations from "./BtnCreations";
import BoxCreations from "./BoxCreations";
import "./Creations.scss";

// DECLARATION OF MY CREATION LIST JSON OBJECT
import print from "../../Object/print.json";
import web from "../../Object/web.json";
import packaging from "../../Object/packaging.json";
import illustration from "../../Object/illustration.json";
import videos from "../../Object/videos.json";
import gif from "../../Object/gif.json";

// const dataCreationsList = [print, web, packaging];
const data = [print, web, packaging, illustration, videos, gif];

export default class Creations extends Component {
	state = {
		name: [
			"Tout",
			"Print",
			"Web",
			"Packaging",
			"Illustration",
			"Vidéos",
			"GIF"
		],
		filterName: [
			"*",
			"print",
			"web",
			"packaging",
			"illustration",
			"videos",
			"gif"
		]
	};
	render() {
		const { name, filterName } = this.state;
		return (
			<React.Fragment>
				<IsotopeReact name={name} filterName={filterName} />
			</React.Fragment>
		);
	}
}

const IsotopeReact = ({ name, filterName }) => {
	// initialize an Isotope object with configs
	// store the isotope object in one state
	const [isotope, setIsotope] = useState(null);
	// store the filter keyword in another state
	const [filterKey, setFilterKey] = useState("*");

	useEffect(() => {
		setIsotope(
			new Isotope(".grid-creations", {
				itemSelector: ".filter-item"

				// layoutMode: "fitRows"
			})
			// layout mode options
		);
	}, []);

	// handling filter key change
	useEffect(() => {
		if (isotope) {
			filterKey === "*"
				? isotope.arrange({ filter: `*` })
				: isotope.arrange({ filter: `.${filterKey}` });
		}
	}, [isotope, filterKey]);

	const creationsListsToFilter = data.map((info, index) => {
		const pub = process.env.PUBLIC_URL;
		return info.map((vals) => {
			return vals.DefaultsCreations.map((elem) => {
				return (
					<BoxCreations
						key={(index += 1)}
						id={index}
						filterItem={elem.item}
						itemsName={elem.itemsName}
						name={elem.alt}
						img={
							elem.name === "storyboard-rpg"
								? `${pub}img/portfolio/videos/projet_storyboad-rpg-vignette.jpg`
								: elem.img && elem.name === "teaser-srg"
								? `${pub}img/portfolio/videos/srg-projet-vignette-bis.jpg`
								: elem.img && elem.name === "showreal-motion-cv"
								? `${pub}img/portfolio/videos/showreal-emilie-vignette.jpg`
								: elem.img
						}
						titleCreations={elem.name}
						alt={elem.alt}
					/>
				);
			});
		});
	});

	return (
		<section className="" id="creations">
			<div className="container-portfolio">
				<div className="grid-btns">
					{filterName.map((list, index) => {
						return (
							<BtnCreations
								key={index}
								btnFilter={name[index]}
								onClick={() => setFilterKey(list)}
							></BtnCreations>
						);
					})}
				</div>
				<div className="container-design">
					<div className="grid-creations">{creationsListsToFilter}</div>
				</div>
			</div>
		</section>
	);
};
